export default function termFilter(term, i18n) {
  let string = "";
  let month = term.replace("M", "");

  if (term === "M24" || term === "M72") {
    string = `${month} ${i18n.t("options.month24")}`;
  } else {
    string = `${month} ${i18n.t("options.month12")}`;
  }

  return string;
}
