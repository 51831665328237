import axios from "axios";
import router from "@/router";

function getOfferDefaultOptions(cpId, authEsa) {
  if (authEsa) {
    return axios.get(`${router.options.base}esa/cp/defaults?cpId=${cpId}`);
  } else {
    return axios.get(`${router.options.base}api/defaults?cpId=${cpId}`);
  }
}

function saveOffer(cpId, authEsa) {
  if (authEsa) {
    return axios.post(`${router.options.base}esa/cp/finalyze?cpId=${cpId}`);
  } else {
    return axios.post(`${router.options.base}api/finalyze?cpId=${cpId}`);
  }
}

function updateFinancing(cpId, percent, term, authEsa) {
  if (authEsa) {
    return axios.post(
      `${router.options.base}esa/cp/nrm?cpId=${cpId}&pp=${percent}&period=${term}`
    );
  } else {
    return axios.post(
      `${router.options.base}api/nrm?cpId=${cpId}&pp=${percent}&period=${term}`
    );
  }
}

function getNRM(cpId, authEsa) {
  if (authEsa) {
    return axios.get(`${router.options.base}esa/cp/nrm?cpId=${cpId}`);
  } else {
    return axios.get(`${router.options.base}api/nrm?cpId=${cpId}`);
  }
}

function getPdfDesire(cpId, authEsa) {
  if (authEsa) {
    return axios.post(`${router.options.base}esa/cp/desire?cpId=${cpId}`);
  } else {
    return axios.post(`${router.options.base}api/desire?cpId=${cpId}`);
  }
}

function getClient(okpo, program, authEsa) {
  let params = `?okpo=${okpo}&program=${program}`;

  if (authEsa) {
    return axios.post(`${router.options.base}esa/cp/addCalcTask${params}`);
  } else {
    return axios.post(`${router.options.base}api/addCalcTask${params}`);
  }
}

function sendPhone(cpId, phone, authEsa) {
  if (authEsa) {
    return axios.post(
      `${router.options.base}esa/cp/addOtpSendTask?cpId=${cpId}&phoneId=${phone}`
    );
  } else {
    return axios.post(
      `${router.options.base}api/addOtpSendTask?cpId=${cpId}&phoneId=${phone}`
    );
  }
}

function sendOtp(cpId, otp, authEsa) {
  if (authEsa) {
    return axios.post(
      `${router.options.base}esa/cp/addOtpValidateTask?cpId=${cpId}&otp=${otp}`
    );
  } else {
    return axios.post(
      `${router.options.base}api/addOtpValidateTask?cpId=${cpId}&otp=${otp}`
    );
  }
}

function currentCondition(cpId, authEsa) {
  if (authEsa) {
    return axios.get(
      `${router.options.base}esa/cp/currentCondition?cpId=${cpId}`
    );
  } else {
    return axios.get(`${router.options.base}api/currentCondition?cpId=${cpId}`);
  }
}

function getRate(cpId, percent, term, authEsa) {
  if (authEsa) {
    return axios.get(
      `${router.options.base}esa/cp/getInterestRate3?cpId=${cpId}&pp=${percent}&period=${term}`
    );
  } else {
    return axios.get(
      `${router.options.base}api/getInterestRate3?cpId=${cpId}&pp=${percent}&period=${term}`
    );
  }
}

function createOffer(data, authEsa) {
  if (authEsa) {
    return axios.post(`${router.options.base}esa/cp/create`, data);
  } else {
    return axios.post(`${router.options.base}api/create`, data);
  }
}

function sendOfferToEmail(cpId, authEsa) {
  if (authEsa) {
    return axios.post(`${router.options.base}esa/cp/sendMail?cpId=${cpId}`);
  } else {
    return axios.post(`${router.options.base}api/sendMail?cpId=${cpId}`);
  }
}

function getStakeOrCalcPledgeDetails(data, calc, authEsa) {
  let url = "";

  if (!authEsa) {
    url = calc ? "api/calcPledgeDetails" : "api/getPledgeDetailsStake";
  } else {
    url = calc ? "esa/cp/calcPledgeDetails" : "esa/cp/getPledgeDetailsStake";
  }

  return axios.post(`${router.options.base}${url}`, data);
}
function getPensionFund(cost, id, authEsa) {
  if (authEsa) {
    return axios.get(
      `${router.options.base}esa/cp/calcPF?cost=${cost}&id=${id}`
    );
  } else {
    return axios.get(`${router.options.base}api/calcPF?cost=${cost}&id=${id}`);
  }
}

function searchDealer(data) {
  return axios.get(`${router.options.base}esa/cp/findorg?okpo=${data}`);
}
function addDealer(cpId, okpo) {
  return axios.post(
    `${router.options.base}esa/cp/addSeller?cpId=${cpId}&sellerOkpo=${okpo}`
  );
}

function needMeeting(cpId) {
  return axios.get(`${router.options.base}esa/meetRequired?cpId=${cpId}`);
}
function makeMeeting(cpId) {
  return axios.post(`${router.options.base}esa/cp/makeMeet?cpId=${cpId}`);
}

function setNRMTableHeadersFinancing759() {
  return [
    {
      text: this.$i18n.t("offer.columns.term"),
      value: "term",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.sumFunding"),
      value: "sumFunding",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.prepaymentSumPFReg"),
      value: "_prepaymentSumPFReg",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.prepayment"),
      value: "prepayment",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.rate"),
      value: "rate",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.totalCostPFReg"),
      value: "totalCostPFReg",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.onceCommission"),
      value: "onceCommission",
      sortable: false
    }
  ];
}
function setNRMTableHeaders() {
  return [
    {
      text: this.$i18n.t("offer.columns.term"),
      value: "term",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.sumFunding"),
      value: "sumFunding",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.totalFunding"),
      value: "totalFunding",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.prepayment"),
      value: "prepayment",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.rate"),
      value: "rate",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.totalCostPFReg"),
      value: "totalCostPFReg",
      sortable: false
    },
    {
      text: this.$i18n.t("offer.columns.onceCommission"),
      value: "onceCommission",
      sortable: false
    }
  ];
}

function sendUrid(cpId, urid, authEsa) {
  if (authEsa) {
    return axios.get(
      `${router.options.base}esa/cp/progr579?cpId=${cpId}&uirdId=${urid}`
    );
  } else {
    return axios.get(
      `${router.options.base}api/progr579?cpId=${cpId}&uirdId=${urid}`
    );
  }
}

export {
  getOfferDefaultOptions,
  saveOffer,
  updateFinancing,
  getNRM,
  getPdfDesire,
  getClient,
  sendPhone,
  sendOtp,
  currentCondition,
  getRate,
  createOffer,
  sendOfferToEmail,
  getStakeOrCalcPledgeDetails,
  getPensionFund,
  searchDealer,
  addDealer,
  needMeeting,
  makeMeeting,
  setNRMTableHeadersFinancing759,
  setNRMTableHeaders,
  sendUrid
};
