export default {
  success: {
    text: "",
    icon: "mdi-check-circle",
    color: "green darken-1"
  },
  error: {
    text: "",
    icon: "mdi-alert-circle",
    color: "red darken-1"
  },
  warning: {
    text: "",
    icon: "mdi-alert",
    color: "orange lighten-1"
  },
  info: {
    text: "",
    icon: "mdi-information",
    color: "blue lighten-2"
  }
};
