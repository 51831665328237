<template>
  <v-snackbar
    v-model="showSnackbar"
    id="my-shack"
    :timeout="props.timeout"
    :color="props.color"
    top
    right
  >
    <v-icon size="25">{{ props.icon }}</v-icon>
    <ul class="text-subtitle-2">
      <li v-for="(text, i) in props.text" :key="i">{{ text }}</li>
    </ul>
    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        icon
        v-bind="attrs"
        @click="$emit('close')"
        aria-label="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "snackbar",
  props: ["show", "props"],
  computed: {
    showSnackbar: {
      get: function() {
        return this.show;
      },
      set: function() {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss">
#my-shack {
  ul {
    list-style: none;
  }
  .v-icon {
    align-self: flex-start;
  }
  .v-snack__action {
    align-self: flex-start;
    margin-top: 8px;
  }
}
</style>
