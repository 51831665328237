import axios from "axios";
import router from "@/router";

function getUserInfo() {
  return axios.get(`${router.options.base}api/userinfo`);
}

function getEsaUserInfo() {
  return axios.get(`${router.options.base}esa/userinfo`);
}

export { getUserInfo, getEsaUserInfo };
