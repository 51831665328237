export default function checkLifeSession(self) {
  if (self.isLogged) {
    self.lifeToken = setInterval(() => {
      let now = new Date();

      if (now.getTime() > +self.$store.state.token) {
        self.$store.dispatch("logout").then(() => {
          self.$store.dispatch("setOfferStatus", "");
          self.$router.push("/auth?message=sessionOut");
        });
      }
    }, 600000); // 5 минут
  }
}
