const REG_EXP_EMAIL = /^([0-9a-zA-Z][-_0-9a-zA-Z.]*[0-9a-zA-Z]|[0-9а-яА-ЯёЁЇїІіЄєҐґ][-_0-9а-я-А-ЯёЁЇїІіЄєҐґ.]*[0-9а-яА-ЯёЁЇїІіЄєҐґ])@([-a-zA-Z]+[.]){1,2}[a-zA-Z]{2,}$/u;
const REG_EXP_PHONE = /^[+]380\d{9}$/;
const MAX_COST = 100000000;
const MAX_FILE_SIZE = 5000000;
const LOCALE = {
  uk: "uk-UA",
  ru: "ru-RU"
};
const MIN_MAX_FIO = /^[-а-яА-ЯёЁЇїІіЄєҐґ`'"\s]{3,100}$/;
const MIN_MAX_NAME = /^[-a-zA-Zа-яА-ЯёЁЇїІіЄєҐ0-9_.`'"()\s]{3,100}$/;
const MIN_MAX_TRANSLATE_NAME = /^[-a-zA-Z0-9_.\s]{3,40}$/;
const MAX_SUBJECT = 5;
const REG_EXP_OPKO = /^[0-9]{8,10}$/;
const SPEC_FILE_TYPE = [
  "PDF",
  "JPG",
  "JPEG",
  "PNG",
  "ODT",
  "DOC",
  "DOCX",
  "XLS",
  "XLSX",
  "ZIP",
  "RAR"
];
const TYPES_COMPENSATION = [{ value: "COST" }, { value: "SUM" }];

export default {
  REG_EXP_EMAIL: REG_EXP_EMAIL,
  REG_EXP_PHONE: REG_EXP_PHONE,
  MAX_COST: MAX_COST,
  MAX_FILE_SIZE: MAX_FILE_SIZE,
  LOCALE: LOCALE,
  MAX_SUBJECT: MAX_SUBJECT,
  MIN_MAX_FIO: MIN_MAX_FIO,
  MIN_MAX_NAME: MIN_MAX_NAME,
  MIN_MAX_TRANSLATE_NAME: MIN_MAX_TRANSLATE_NAME,
  REG_EXP_OPKO: REG_EXP_OPKO,
  SPEC_FILE_TYPE: SPEC_FILE_TYPE,
  TYPES_COMPENSATION: TYPES_COMPENSATION
};
