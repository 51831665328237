import axios from "axios";
import router from "@/router";

function getDefaultSettings() {
  return axios.get(`${router.options.base}api/manage/defaults`);
}
function getEmployees() {
  return axios.get(`${router.options.base}api/manage/getEmployees`);
}
function sendEmployee(data, type) {
  if (type === "add") {
    return axios.post(`${router.options.base}api/manage/addEmployee`, data);
  }
  if (type === "update") {
    return axios.post(`${router.options.base}api/manage/editEmployee`, data);
  }
}
function actionEmployee(id, action) {
  if (action === "remove") {
    return axios.post(`${router.options.base}api/manage/fire?employeeId=${id}`);
  }
  if (action === "activate") {
    return axios.post(
      `${router.options.base}api/manage/actualize?employeeId=${id}`
    );
  }
  if (action === "lock") {
    return axios.post(`${router.options.base}api/manage/lock?employeeId=${id}`);
  }
  if (action === "unlock") {
    return axios.post(
      `${router.options.base}api/manage/unlock?employeeId=${id}`
    );
  }
}

function getCompanies() {
  return axios.get(`${router.options.base}esa/getAllOrg`);
}
function searchCompanyByOkpo(okpo) {
  return axios.get(`${router.options.base}esa/org/search?okpo=${okpo}`);
}
function searchCompanyById(id) {
  return axios.get(`${router.options.base}esa/org/get?id=${id}`);
}
function sendCompany(data, type) {
  if (type === "add") {
    return axios.post(`${router.options.base}esa/org/add`, data);
  }
  if (type === "update") {
    return axios.post(`${router.options.base}esa/org/edit`, data);
  }
}
function getStaffCompany(id) {
  return axios.get(`${router.options.base}esa/getEmployeeByOrg?orgId=${id}`);
}
function sendEmployeeEsa(data, type) {
  if (type === "add") {
    return axios.post(`${router.options.base}esa/addEmployee`, data);
  }
  if (type === "update") {
    return axios.post(`${router.options.base}esa/editEmployee`, data);
  }
}
function actionEmployeeEsa(id, action) {
  if (action === "block") {
    return axios.post(
      `${router.options.base}esa/employee/block?employeeId=${id}`
    );
  }
  if (action === "unblock") {
    return axios.post(
      `${router.options.base}esa/employee/unblock?employeeId=${id}`
    );
  }
}
function getStoresCompany(orgId) {
  return axios.get(`${router.options.base}esa/getStoreByOrg?orgId=${orgId}`);
}
function sendStore(data, type) {
  if (type === "add") {
    return axios.post(`${router.options.base}esa/store/add`, data);
  }
  if (type === "update") {
    return axios.post(`${router.options.base}esa/store/edit`, data);
  }
}
function interactionDataAddress(url, method) {
  switch (method) {
    case "POST":
      return axios.post(`${router.options.base}${url}`);
    case "GET":
      return axios.get(`${router.options.base}${url}`);
  }
}
function searchManager(data) {
  return axios.get(`${router.options.base}esa/searchManager?ldapOrFio=${data}`);
}
function createDataForTableStaff(data, self) {
  return data.map((item, idx) => {
    let orgId = self.$route?.query?.orgId || "";

    return {
      ...item,
      _id: idx + 1,
      _role: self.$i18n.t(`options.positions.${item.role.value}`),
      _status: self.$i18n.t(`settings.status.${item.status}`),
      _orgId: +orgId
    };
  });
}

export {
  getDefaultSettings,
  getEmployees,
  sendEmployee,
  actionEmployee,
  getCompanies,
  searchCompanyByOkpo,
  searchCompanyById,
  sendCompany,
  getStaffCompany,
  sendEmployeeEsa,
  actionEmployeeEsa,
  getStoresCompany,
  sendStore,
  interactionDataAddress,
  searchManager,
  createDataForTableStaff
};
