import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "login",
    meta: { layout: "empty" },
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/authEsa",
    name: "login",
    meta: { layout: "empty" },
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/agreement",
    name: "agreement",
    meta: { layout: "empty" },
    component: () => import("@/views/Agreement.vue")
  },
  {
    path: "/orders",
    name: "orders",
    meta: { layout: "main", isAgreement: true },
    component: () => import("@/views/Orders.vue")
  },
  /*{
    path: "/archive",
    name: "archive",
    meta: { layout: "main", isAgreement: true },
    component: () => import("@/views/Archive.vue")
  },*/
  {
    path: "/statistic",
    name: "statistic",
    meta: { layout: "main", isAgreement: true },
    component: () => import("@/views/Statistic.vue")
  },
  {
    path: "/settings",
    meta: { layout: "main", isAgreement: true },
    component: () => import("@/views/Settings.vue"),
    children: [
      {
        path: "",
        name: "settings",
        meta: { layout: "main", isAgreement: true }
      },
      {
        path: "companies",
        name: "companies",
        meta: { layout: "main", isAgreement: true },
        component: () => import("@/components/settings/SettingsCompanies")
      },
      {
        path: "staff",
        name: "Staff",
        meta: { layout: "main", isAgreement: true },
        component: () => import("@/components/settings/SettingsStaff")
      },
      {
        path: "stores",
        name: "Stores",
        meta: { layout: "main", isAgreement: true },
        component: () => import("@/components/settings/SettingsStores")
      },
      {
        path: "company/:id",
        name: "company",
        meta: { layout: "main", isAgreement: true },
        component: () => import("@/components/settings/SettingsCompany")
      },
      {
        path: "employee/:id",
        name: "SettingsEmployee",
        meta: { layout: "main", isAgreement: true },
        component: () => import("@/components/settings/SettingsEmployee")
      },
      {
        path: "store/:id",
        name: "SettingsStore",
        meta: { layout: "main", isAgreement: true },
        component: () => import("@/components/settings/SettingsStore")
      },
      {
        path: "",
        redirect: "/settings/staff"
      }
    ]
  },
  {
    path: "/offer",
    name: "offer",
    meta: { layout: "main", isAgreement: true },
    component: () => import("@/views/Offer.vue")
  },
  {
    path: "/detail/:id",
    name: "detail",
    meta: { layout: "main", isAgreement: true },
    component: () => import("@/views/Detail.vue")
  },
  /*{
    path: "/home",
    name: "home",
    meta: { layout: "main", isAgreement: true },
    component: () => import("@/views/Home.vue")
  },*/
  {
    path: "/pb/start",
    name: "preloader",
    meta: { layout: "empty", isAgreement: true },
    component: () => import("@/views/Preloader.vue")
  },
  {
    path: "/error/:id",
    name: "Error",
    component: () => import("@/views/Error.vue")
  },
  {
    path: "/",
    redirect: "/auth"
  },
  {
    path: "*",
    redirect: "/error/404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === "preloader") {
    next();
    return;
  }

  if (from.name === "offer" && store.getters.getStatus) {
    if (to.name === "login") {
      store.commit("show_dialog", "logout");
    } else if (to.name !== "offer") {
      store.commit("show_dialog", `close_offer_${to.name}`);
    }

    return;
  }

  if (from.name === "offer") {
    store.commit("offer_status", "");
  }

  let isLogged = store.getters.isLogged;
  let user = store.getters.getUser.user;

  if (to.matched.some(record => record.meta.isAgreement)) {
    let agreement = user?.agrSignDt;

    if (!isLogged) {
      next("/auth");
    } else if (!agreement) {
      next("/agreement");
    } else {
      next();
    }
  } else {
    next();
  }
  if (to.path.includes("settings")) {
    setTimeout(() => {
      document.getElementById("nav-settings")?.classList.add("v-btn--active");
    }, 200);
  }
  if (from.path.includes("settings") && !to.path.includes("settings")) {
    setTimeout(() => {
      document
        .getElementById("nav-settings")
        ?.classList.remove("v-btn--active");
    }, 200);
  }
});

export default router;
