import CONST from "@/utils/constants";

export default function dateFilter(value, format = "date") {
  const options = {};
  const lang = localStorage.getItem("lang");

  if (format.includes("date")) {
    options.day = "2-digit";
    options.month = "long";
    options.year = "numeric";
  }

  if (format.includes("time")) {
    options.hour = "2-digit";
    options.minute = "2-digit";
    options.second = "2-digit";
  }

  if (format.includes("numeric")) {
    options.day = "2-digit";
    options.month = "2-digit";
    options.year = "numeric";
  }

  return new Intl.DateTimeFormat(CONST.LOCALE[lang], options).format(
    new Date(value)
  );
}
