<template>
  <v-footer class="font-weight-medium justify-space-between">
    <div
      class="text-left text-subtitle-2 py-2 pa-sm-2 d-inline-flex flex-column"
    >
      <span class="">{{ $t("title.footer") }}</span>
      <strong>
        <!--<v-icon small class="mr-1 ml-3">mdi-account</v-icon>-->
        {{ helpManager }},
        <a
          :href="`tel:${managerPhone}`"
          class="text-decoration-none d-inline-flex align-baseline ml-1"
        >
          <v-icon small class="mr-1">mdi-phone-in-talk</v-icon>
          {{ managerPhone }}
        </a>
        <a
          :href="`mailto:${managerEmail}`"
          class="text-decoration-none d-inline-flex align-baseline ml-2"
        >
          <v-icon small class="mr-1">mdi-email</v-icon>
          {{ managerEmail }}
        </a>
      </strong>
    </div>
    <div class="text-subtitle-2 pb-2 pa-sm-2 align-self-end">
      <a
        href="https://privatbank.ua/business/lizing"
        class="text-decoration-none"
        target="_blank"
        rel="noopener noreferrer"
      >
        Розрахунок стандартної КП
      </a>
    </div>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppFooter",
  computed: {
    ...mapGetters(["getUser"]),
    manager() {
      return this.getUser && this.getUser.manager;
    },
    helpManager() {
      let arrFio = this.manager ? this.manager.fio.split(" ") : ["", ""];
      return `${arrFio[0]} ${arrFio[1]}`;
    },
    managerPhone() {
      return this.manager && this.manager.phone;
    },
    managerEmail() {
      return this.manager && this.manager.email;
    }
  }
};
</script>
