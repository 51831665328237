<template>
  <div class="app-main-layout">
    <app-header :drawer="drawer" @chowDrawer="drawer = !drawer"></app-header>

    <aside>
      <v-navigation-drawer
        v-model="drawer"
        class="grey lighten-5"
        temporary
        app
      >
        <app-navbar></app-navbar>
      </v-navigation-drawer>
    </aside>

    <v-main style="padding: 64px 0 20px">
      <v-fade-transition mode="out-in">
        <router-view class="pt-7"></router-view>
      </v-fade-transition>
    </v-main>

    <app-footer v-if="!getPointOfEntry && status !== 'create'"></app-footer>

    <v-dialog v-model="showDialog" width="320" persistent>
      <v-card class="pa-3">
        <v-card-title class="headline pa-2 d-flex justify-space-between">
          {{ $t("confirm.attention.title") }}
          <v-btn
            icon
            small
            @click="closeDialog"
            :ripple="false"
            class="close-dialog mt-n4 mr-n2"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="subtitle-2 px-2 pt-2 pb-4">
          {{ msgDialog }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="privat"
            dark
            elevation="1"
            width="47%"
            @click="confirmDialog"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </div>
</template>

<script>
import AppHeader from "@/components/app/AppHeader";
import AppNavbar from "@/components/app/AppNavbar";
import AppFooter from "@/components/app/AppFooter";
import { mapGetters } from "vuex";
import checkLifeSession from "@/utils/lifeSession";
import showSnack from "@/mixins/showSnack.mixins";

export default {
  name: "mainLayout",
  mixins: [showSnack],
  components: {
    AppHeader,
    AppNavbar,
    AppFooter
  },
  data: () => ({
    drawer: null
  }),
  computed: {
    ...mapGetters([
      "isLogged",
      "getStatus",
      "getShowDialog",
      "getPointOfEntry",
      "getUser"
    ]),
    status() {
      return this.getStatus;
    },
    showDialog() {
      return !!this.getShowDialog;
    },
    msgDialog() {
      let msg = "";

      switch (this.getStatus) {
        case "create":
          msg = this.$i18n.t("confirm.attention.not_save_data");
          break;
        default:
          break;
      }

      if (this.getShowDialog === "logout") {
        msg += this.$i18n.t("confirm.attention.confirm_logout");
      } else {
        msg += this.$i18n.t("confirm.attention.close_offer");
      }

      return msg;
    }
  },
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => this.$router.push("/auth?message=logout"));
    },
    esaLogout() {
      this.$store.dispatch("esaLogout").then(() => {
        this.$router.push("/authEsa?message=logout");
      });
    },
    closeDialog() {
      this.$store.dispatch("showDialog", false);
    },
    confirmDialog() {
      if (this.getShowDialog === "logout") {
        this.$store.dispatch("setOfferStatus", "");
        !this.getPointOfEntry ? this.logout() : this.esaLogout();
      }
      if (this.getShowDialog.includes("close_offer")) {
        let route = this.getShowDialog.split("_")[2];

        this.$store.dispatch("setOfferStatus", "");
        this.$router.push(`/${route}`);
      }
      this.$store.dispatch("showDialog", false);
    },
    isActualEnd() {
      if (this.getUser.isActualEndsSoon) {
        this.snack.icon = "alert-circle";
        this.showSnack(
          "warning",
          [this.$i18n.t(`error.isActualEndsSoon`)],
          20000
        );
      }
    }
  },
  mounted() {
    this.isActualEnd();
    checkLifeSession(this);
  },
  beforeDestroy() {
    clearInterval(this.lifeToken);
  }
};
</script>
