import axios from "axios";
import router from "@/router";

function sendPhone(fomData) {
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };

  return axios.post(`${router.options.base}api/login`, fomData, {
    headers: headers
  });
}
function sendOtp(otp) {
  return axios.post(`${router.options.base}otp/check?otp=${otp}`);
}
function logout() {
  return axios.get(`${router.options.base}api/logout`);
}

function esaAuth() {
  return axios.get(`${router.options.base}esa/pb`);
}
function esaLogout() {
  return axios.get(`${router.options.base}esa/logout`);
}
function createToken() {
  let now = new Date();

  return now.getTime() + 25200000; // 7 hour
}
function agreement() {
  return axios.post(`${router.options.base}api/agree`);
}

export {
  sendPhone,
  sendOtp,
  logout,
  esaAuth,
  esaLogout,
  createToken,
  agreement
};
