import snacks from "@/utils/snacksTypes";

export default {
  data() {
    return {
      show: false,
      snack: {
        text: "",
        timeout: -1,
        icon: "",
        color: ""
      }
    };
  },
  methods: {
    showSnack(type, text, timeout) {
      this.snack = snacks[type];
      this.snack.text = text;
      this.snack.timeout = timeout ? timeout : 20000;
      this.show = true;
    }
  }
};
