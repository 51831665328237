export default function errorHandler(err, code) {
  console.log("errorHandler response - ", err?.response);
  console.log("errorHandler request - ", err?.request);
  let status = err?.response?.status;
  let data = err?.response?.data;

  if (status === 400 && data) {
    if (data.object && data.object[0].errorCode) {
      return data.object[0].errorCode;
    } else {
      return code;
    }
  }

  return code ? code : "other";
}
